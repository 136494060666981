<template>
  <div class="bookingManagement">
    <div class="flex-box">
      <div class="left">
        <el-button type="success">下载</el-button>
      </div>
    </div>

    <div class="flex-box">
        <div class="flex-wrap flex-box">
            <div class="margin-top flex-box">
                <classSelector class="" @change="classChange"></classSelector>
                <el-input
                    class="picker"
                    v-model="schoolStudents"
                    placeholder="请输入在校学生" >
                </el-input>
                <el-select class="picker" v-model="value" placeholder="请选择状态">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="margin-top flex-box">
              <el-date-picker v-model="queryTime"
                  type="daterange"
                  class="header-query-frame"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>

                <!-- <el-date-picker
                class=""
                v-model="value1"
                type="date"
                placeholder="开始时间"
                >
                </el-date-picker>
                <el-date-picker
                class="picker"
                v-model="value2"
                type="date"
                placeholder="结束时间"
                >
                </el-date-picker> -->
                <el-input
                class="picker"
                v-model="schoolVisitor"
                placeholder="请输入访校人"
                ></el-input>
            </div>
            
        </div>

        <el-button @click="getBookingList(1)" class="picker" type="primary">查 询</el-button>
    </div>

    <el-table :data="bookingList" style="width: 100%">
      <el-table-column prop="nickname" label="在校学生" align="center">
      </el-table-column>
      <el-table-column prop="date" label="在校学生班级" align="center">
      </el-table-column>
      <el-table-column prop="parentData" label="访校人" align="center">
        <template slot-scope="scope">
          <span v-for="item in scope.row.parentData" :key="item.visitName">
            {{ item.visitName }},
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="parentData" label="关系" align="center">
        <template slot-scope="scope">
          <span v-for="item in scope.row.parentData" :key="item.visitRelation">
            {{ item.visitRelation }},
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="访校开始时间" align="center">
      </el-table-column>
      <el-table-column prop="endTime" label="访校结束时间" align="center">
      </el-table-column>
      <el-table-column prop="timeLength" label="时长" align="center">
      </el-table-column>
      <el-table-column prop="date" label="访校原因" align="center">
      </el-table-column>
      <el-table-column prop="visitPhone" label="联系方式" align="center">
      </el-table-column>
      <el-table-column prop="date" label="是否有车" align="center">
      </el-table-column>
      <el-table-column prop="date" label="状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0">审核中</el-tag>
          <el-tag v-else-if="scope.row.status == 1" type="success">已通过</el-tag>
          <el-tag v-else type="danger">未通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" align="center" width="145">
        <template slot-scope="scope">
          <el-button
            @click="statusChange(scope.row,1)"
            type="primary"
            size="small"
            >同意</el-button
          >
          <el-button
            @click="statusChange(scope.row,2)"
            type="warning"
            size="small"
            >拒绝</el-button
          >
        </template>
      </el-table-column>
      <!-- 访校结束时间 -->
    </el-table>
  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
// import classSelector from "@/components/selectors/classSelector";
export default {
  components: { classSelector },
  data() {
    return {
      page: { pageCount: 1, currentPage: 0 },
      schoolStudents: "",
      // 状态数组
      statusList: [
        { value: 0, label: "审核中" },
        { value: 1, label: "已通过" },
        { value: 3, label: "未通过" },
      ],
      value: "",
      value1: "",
      value2: "",
      schoolVisitor: "",
      bookingList: [],
      bookingDialogVisible: false,
      data: {},
      queryTime:'',
    };
  },
  created() {
    this.getBookingList(1);
  },
  methods: {
    classChange(value) {
      // this.classList = value
    },
    // 加载
    getBookingList(page) {

      const that = this;
      let data = {
        indexNo: page,
        rows: 10,
        status:this.value,
        startTime:this.queryTime[0],
        endTime:this.queryTime[1],
        // startTime:this.value1,
        // endTime:this.value2,
        visitName:this.schoolVisitor,
      };
      this.$postJson("/visit/apply/queryVisitList", data)
        .then((res) => {
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;
          res.data.rows.forEach((item) => {
            item.timeLength = item.timeLength + "小时";
          });
          that.bookingList = res.data.rows;
          console.log(that.bookingList);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    //  状态改变， 同意就通过，拒绝就不通过
    statusChange(row, type) {
      const that = this;
      let title = "";
      if (type == 1) {
        title = "是否通过审核";
      } else {
        title = "是否不通过审核";
      }
      this.$confirm(`${title}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
            status: type,
          };
          this.$postJson(`/visit/apply/editStatus`, data)
            .then((res) => {
              that.$message({
                type: "success",
                message: "状态更改成功!",
              });
              that.getBookingList(that.page.currentPage);
            })
            .catch((err) => {
              console.log(err);
            });
          // that.getBookingList(that.page.currentPage);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消状态更改",
          });
        });
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  
}

.flex-wrap {
    width: 800px;
    flex-wrap: wrap;
    /* align-items: center; */
}

.margin-top {
    margin-top: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

/* .width {
  width: 135px;
} */
</style>